import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  createContext,
  useContext,
} from 'react';
import { GridRowParams } from '@material-ui/data-grid';
import {
  formatCurrencyAsText,
  transformAsCurrency,
  validaCPF,
  validarCNPJ,
} from '~/utils/functions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  autorizado,
  condicao,
  limite,
  moduloSchema,
  schema,
} from './Validations';
import {
  AddAutorizado,
  ICondicao,
  ICredito,
  Autorizado,
  Convenio,
  Credito,
  Condicao,
  Options,
  SelectEntidades,
  SelectStatus,
  CondicaoData,
  AddClassificacao,
  IModulo,
  CidadeParams,
} from './types';
import api from '~/services/api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import geral from './defaultData/geral.json';
import autorizadoDefaults from './defaultData/autorizado.json';
import condicaoDefaults from './defaultData/condicao.json';
import limitDefaults from './defaultData/limite.json';
import moduloDefaults from './defaultData/modulo.json';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { ICEP, ICNPJ } from '~/components/Pessoa/types';
import moment from 'moment';
import {
  addUpdate,
  insertOrUpdate,
  MasterDetailProps,
} from '~/utils/masterDetail';
import axios from 'axios';
import { validar } from '~/utils/ie';
import { LojaContext } from '~/context/loja';
import { AnyObject } from 'yup/lib/types';
import { aplicacoes } from './defaultData';
import { loadingContext } from '~/context/loading';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

const MySwal = withReactContent(Swal);

interface RefObject {
  changeStartDate: (data: string) => any;
  changeDate: (data: string) => any;
}
const ClienteContext = createContext({} as any);

export function ClienteProvider({ children }: any) {
  const { loja } = useContext(LojaContext);
  const { setLoading } = useContext(loadingContext);

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [tab, setTab] = useState<any>('geral');
  const [iniInicializado, setInitInicializado] = useState(false);
  const [moduloAdmin, setModuloAdmin] = useState(false);
  // Estado da Aba Geral
  const [codClienteData, setCodClienteData] = useState<any>(undefined);
  const [tipoPessoa, setTipoPessoa] = useState<number>(0);
  const [pessoaExists, setPessoaExists] = useState<boolean>(false);
  const [loadingIcon, setLoadingIcon] = useState<string>();
  const [flagInativo, setFlagInativo] = useState<boolean>(false);
  const nascimentoRef = useRef<RefObject>();
  const [cidade, setCidade] = useState<CidadeParams>();
  const [clearCidade, setClearCidade] = useState<boolean>(false);
  const [convenios, setConvenios] = useState<Options[]>([]);
  const [conveniosAtivos, setConveniosAtivos] = useState<Options[]>([]);
  const [finalizadoras, setFinalizadoras] = useState<Options[]>([]);

  const [statusDoPdv, setStatusPdv] = useState<Options[]>([]);
  const [classificacao, setClassificacao] = useState<Options[] | null>();
  const [diasCondicao, setDiasCondicao] = useState<any>();

  // Estados da Aba Crédito/Profissional
  const [orgaoPublico, setOrgaoPublico] = useState<boolean>(false);
  const [tipoOrgaoPublico, setTipoOrgaoPublico] = useState<number>();
  const [empresaConvenio, setEmpresaConvenio] = useState<boolean>(false);
  const [microEmpresa, setMicroEmpresa] = useState<boolean>(false);
  const [produtorRural, setProdutorRural] = useState<boolean>(false);
  const admissaoRef = useRef<RefObject>();
  const [limiteAEditar, setLimiteAEditar] = useState<any>(undefined);
  const [limitesDeCredito, setLimitesDeCredito] = useState<ICredito[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const masterDetailDefault = [
    {
      obj_name: 'limite',
      pk_fields: ['cod_cliente', 'cod_finalizadora'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'autorizados',
      pk_fields: ['cod_cliente', 'cod_pessoa_autorizado'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'condicao',
      pk_fields: ['cod_seq_pessoa_condicao'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
    {
      obj_name: 'modulo',
      pk_fields: ['cod_seq_modulo'],
      itens: {
        insert: [],
        update: [],
        delete: [],
      },
    },
  ];

  // Estado responsável pelos dados da masterDetail
  const [masterDetail, setMasterDetail] =
    useState<MasterDetailProps[]>(masterDetailDefault);

  // Estados da Aba Autorizados
  const [autorizadoAEditar, setAutorizadoAEditar] = useState<any>(undefined);
  const [codigoPessoaAutorizada, setCodigoPessoaAutorizada] =
    useState<any>(undefined);
  const [autorizados, setAutorizados] = useState<AddAutorizado[]>([]);

  // Estados da aba Condicao
  const [condicoes, setCondicoes] = useState<ICondicao[]>([]);
  const [clickSubmit, setClickSubmit] = useState<boolean>(false);
  const [initFinalizadora, setInitFinalizadora] = useState<boolean>(false);
  const [condicoesOptions, setCondicoesOptions] = useState<Options[]>([]);
  const [condicaoAEditar, setCondicaoAEditar] = useState<any>(undefined);
  const [classificacaoOptions, setClassificacaoOptions] = useState<Options[]>(
    [],
  );

  // Estados da aba Admin
  const [modulosOptions, setModulosOptions] = useState<any[]>([]);
  const [aplicacoesData, setAplicacoesData] = useState<any[]>([]);
  const [flgDemonstracao, setFlgDemonstracao] = useState(false);
  const [flgAtivo, setFlgAtivo] = useState(false);
  const [modulos, setModulos] = useState<any[]>([]);
  const [modulosAEditar, setModulosAEditar] = useState<any>(undefined);

  /**
   * UseForm para controlar os formulário da Aba Geral.
   */
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
    formState: { isDirty, errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  useEffect(() => {
    (async () => {
      if (errors) {
        if (
          !errors.bairro &&
          !errors.cep &&
          !errors.cidade &&
          !errors.estados &&
          !errors.logradouro &&
          !errors.name &&
          !errors.num_cpf &&
          !errors.num_cnpj &&
          !errors.num_ie &&
          !errors.sexo
        ) {
          if (errors.diaFechamento) setTab('credprof');
        } else {
          setTab('geral');
        }
      }
    })();
  }, [errors]);

  /**
   * @constant watchTipoPessoa Variável que armazena o tipo de pessoa selecionada pelo usuário.
   */
  const watchTipoPessoa: number = watch('tipoPessoa');

  /**
   * UseForm para controlar os formulário da Aba Credito/Profissional.
   */
  const {
    register: limiteRegister,
    handleSubmit: handleSubmitLimite,
    control: limiteControl,
    reset: resetLimite,
    setValue: setValueLimite,
    formState: formStateLimite,
  } = useForm({
    resolver: yupResolver(limite),
    reValidateMode: 'onBlur',
  });

  /**
   * UseForm para controlar os formulário da Aba Credito/Profissional.
   */
  const {
    register: autorizadoRegister,
    handleSubmit: handleSubmitAutorizado,
    control: autorizadoControl,
    reset: resetAutorizado,
    setValue: setValueAutorizado,
    getValues: getValuesAutorizado,
    formState: formStateAutorizado,
  } = useForm({
    resolver: yupResolver(autorizado),
    reValidateMode: 'onBlur',
  });

  /**
   * UseForm para controlar os formulário da Aba Condição.
   */
  const {
    register: condicaoRegister,
    handleSubmit: handleSubmitCondicao,
    control: condicaoControl,
    reset: resetCondicao,
    setValue: setValueCondicao,
    formState: formStateCondicao,
  } = useForm({
    resolver: yupResolver(condicao),
    reValidateMode: 'onBlur',
  });

  const {
    register: registerAdmin,
    clearErrors: clearErrorsAdmin,
    handleSubmit: handleSubmitAdmin,
    getValues: getValuesAdmin,
    setError: setErrorAdmin,
    watch: watchAdmin,
    control: controlAdmin,
    reset: resetAdmin,
    setValue: setValueAdmin,
    formState: formStateAdmin,
  } = useForm({
    resolver: yupResolver(moduloSchema),
    reValidateMode: 'onChange',
  });

  /**
   * Busca informações iniciais da api
   */
  useEffect(() => {
    (async () => {
      if (clickSubmit) {
        if (errors.email) {
          setClickSubmit(false);
          toast.warning('E-mail inválido');
        }

        if (errors.estados) {
          setClickSubmit(false);
          setInitInicializado(true);
        }
      }
    })();
  }, [
    errors.email,
    errors.estados,
    formStateLimite.errors.finalizadora,
    clickSubmit,
  ]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/aplicacoes');
      if (data.data.length > 0) {
        const formatter = data.data.map((item: any) => {
          return {
            value: item.cod_aplicacao,
            label: item.descricao,
          };
        });
        setAplicacoesData([...aplicacoes, ...formatter]);
      } else {
        setAplicacoesData([]);
      }
    })();
    setValueAdmin('flg_ativo', false);
    setValueAdmin('flg_demonstracao', false);
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/modulo');
      if (data.data.length > 0) {
        const formatter = data.data.map((item: any) => {
          return {
            value: item.cod_modulo,
            label: item.des_modulo,
          };
        });
        setModulosOptions(formatter);
      } else {
        setModulosOptions([]);
      }
    })();
  }, []);

  const getConvenios = async () => {
    const { data } = await api.get('/pessoas/convenio');

    if (data.success) {
      if (data.message !== 'Nenhum registro encontrado.') {
        const optionsAtivos = data.data.flatMap((emp: Convenio) => {
          if (emp.flg_inativo !== true) {
            return [
              {
                label: emp.nome_pessoa,
                value: emp.cod_pessoa,
              },
            ];
          }
          return [];
        });
        optionsAtivos.unshift({
          value: null,
          label: 'Nenhum',
        });

        setConveniosAtivos(optionsAtivos);

        const options = data.data.map((emp: Convenio) => ({
          label: emp.nome_pessoa,
          value: emp.cod_pessoa,
        }));
        options.unshift({
          value: null,
          label: 'Nenhum',
        });
        setConvenios(options);
      }
    }
  };

  useEffect(() => {
    (async () => {
      getConvenios();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/finalizadora');
      if (data.success) {
        if (data.message !== 'Nenhum registro encontrado.') {
          const options = data.data.map((ent: SelectEntidades) => ({
            label: `${ent.des_finalizadora}`,
            value: ent.cod_finalizadora,
          }));

          setFinalizadoras(options);
        }
      }

      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);
      setDiasCondicao(30);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/statuspdv');

      if (data.success) {
        if (data.data.length > 0) {
          const options = data.data.map((emp: SelectStatus) => ({
            label: `${emp.cod_status_pdv} - ${emp.des_status_pdv}`,
            value: emp.cod_status_pdv,
          }));

          setStatusPdv(options.sort((a: any, b: any) => a.value - b.value));
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/modulo-admin');
      if (data.data.length > 0) {
        setModuloAdmin(true);
      } else {
        setModuloAdmin(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/condicao');
      if (data.success && data.message !== 'Nenhum registro encontrado.') {
        const formatedOptions = data.data.map((cond: CondicaoData) => ({
          label: `${cond.des_condicao} - ${cond.des_definicao}`,
          value: cond.cod_condicao,
        }));
        setCondicoesOptions(formatedOptions);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const tipo = watchTipoPessoa && watchTipoPessoa === 1 ? 0 : 1;
      const { data } = await api.get(`/classificacao-parceiro/${tipo}`);
      if (data.success) {
        const formatedOptions = data.data.map(
          (cond: { cod_classificacao: number; des_classificacao: string }) => ({
            label: cond.des_classificacao,
            value: cond.cod_classificacao,
          }),
        );
        setClassificacao(null);
        setClassificacaoOptions(formatedOptions);
      }
    })();
  }, [watchTipoPessoa]);

  function getArrayIndexed(arr: any, fieldNameKey: any, fieldNameValue: any) {
    const r: any = {};
    for (let i = 0; i < arr.length; i++)
      r[`${arr[i][fieldNameKey]}`] = arr[i][fieldNameValue];
    return r;
  }
  const indiceValorFinalizadora = getArrayIndexed(
    finalizadoras,
    'value',
    'label',
  );
  const indiceValorCondicao = getArrayIndexed(
    condicoesOptions,
    'value',
    'label',
  );

  const documentoValido = (doc: string): boolean => {
    const isCPF = tipoPessoa === 0;

    const validarDocumento = (numDocumento: string): boolean => {
      if (!numDocumento) {
        toast.warn(`${isCPF ? 'CPF' : 'CNPJ'} informado é inválido`);
        return false;
      }

      const isValid = isCPF
        ? validaCPF(numDocumento)
        : validarCNPJ(numDocumento);

      if (!isValid) {
        toast.warn(`${isCPF ? 'CPF' : 'CNPJ'} informado é inválido`);
      }

      return isValid;
    };

    return isCPF ? validarDocumento(doc) : validarDocumento(doc);
  };
  /**
   * Métodos genéricos da tela
   */
  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      const { row } = param;
      const {
        cod_cliente,
        cod_pessoa,
        tipo_pessoa,
        flg_inativo,
        cod_convenio,
        des_empresa_trab,
        num_telefone_trab,
        des_cargo_trab,
        dta_admissao_trab,
        apelido_fantasia,
        cod_contato,
        cod_endereco,
        des_bairro,
        des_cidade,
        des_complemento,
        des_contato,
        des_logradouro,
        des_uf,
        dta_nascimento_abertura,
        email,
        flg_emp_convenio,
        flg_micro_empresa,
        flg_orgao_publico,
        flg_produtor_rural,
        nome_pessoa,
        num_celular,
        num_cep,
        num_cpf_cnpj,
        num_dia_fecha,
        num_endereco,
        num_rg_ie,
        num_ie,
        num_telefone,
        sexo,
        site,
        tipo_orgao_publico,
        cod_cliente_local,
        cod_aplicacao,
      } = row;
      setPessoaExists(true);
      setUpdate(true);

      if (sexo === 0) {
        setValue('sexo', {
          label: 'Masculino',
          value: 0,
        });
      } else {
        setValue('sexo', {
          label: 'Feminino',
          value: 1,
        });
      }
      getConvenios();
      const convenio = convenios.filter((conv) => conv.value === cod_convenio);
      setTipoPessoa(tipo_pessoa);
      setCodClienteData(cod_cliente);
      setValue('cod_cliente', cod_cliente);
      if (num_cpf_cnpj) {
        if (num_cpf_cnpj.length <= 11) {
          setValue('num_cpf', num_cpf_cnpj);
          setTipoPessoa(0);
          setValue('tipoPessoa', 0);
        } else {
          setValue('num_cnpj', num_cpf_cnpj);
          setTipoPessoa(1);
          setValue('tipoPessoa', 1);
        }
      }
      setValue('cod_pessoa', cod_pessoa);
      setValue('cod_contato', cod_contato);
      setValue('cod_endereco', cod_endereco);
      setValue('num_rg_ie', num_rg_ie);
      setValue('num_ie', num_ie === null ? '' : num_ie);
      setValue('inativo', flg_inativo);
      setFlagInativo(flg_inativo);
      setValue('name', nome_pessoa);
      setValue('apelido', apelido_fantasia);
      setValue('apelido', apelido_fantasia);
      setValue('telefone', num_telefone);
      setValue('celular', num_celular);
      setValue('contato', des_contato);
      setValue('email', email);
      setValue('site', site);
      setValue('cep', num_cep);
      setValue('logradouro', des_logradouro);
      setValue('numero', num_endereco);
      setValue('bairro', des_bairro);
      setValue('complemento', des_complemento);
      setCidade({
        cidade: des_cidade,
        uf: des_uf,
      });
      setValue(
        'nascimentoAbertura',
        dta_nascimento_abertura
          ? moment(createDateWithoutTimezone(dta_nascimento_abertura)).format(
              'YYYY-MM-DD',
            )
          : null,
      );
      nascimentoRef.current?.changeDate(dta_nascimento_abertura);
      setOrgaoPublico(flg_orgao_publico);
      setValue('orgaoPublico', flg_orgao_publico);
      setTipoOrgaoPublico(tipo_orgao_publico);
      setValue('tipoOrgaoPublico', tipo_orgao_publico);
      setEmpresaConvenio(flg_emp_convenio);
      setValue('empresaConvenio', flg_emp_convenio);
      setValue('diaFechamento', num_dia_fecha);
      setMicroEmpresa(flg_micro_empresa);
      setValue('microEmpresa', flg_micro_empresa);
      setProdutorRural(flg_produtor_rural);
      setValue('produtorRural', flg_produtor_rural);
      setValue(
        'conveniado',
        convenio.length > 0
          ? convenio[0]
          : {
              value: null,
              label: 'Nenhum',
            },
      );
      setValue('desEmpresaTrabalho', des_empresa_trab);
      setValue('telefoneTrabalho', num_telefone_trab);
      setValue('cargo', des_cargo_trab);
      setValue(
        'admissao',
        dta_admissao_trab
          ? moment(createDateWithoutTimezone(dta_admissao_trab)).format(
              'YYYY-MM-DD',
            )
          : null,
      );

      if (dta_admissao_trab) {
        admissaoRef.current?.changeDate(
          moment(createDateWithoutTimezone(dta_admissao_trab)).format(
            'YYYY-MM-DD',
          ),
        );
      }

      setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
      setValueCondicao('numCondicao', 30);

      const findAplicacoes = aplicacoesData.find(
        (item) => item.value === cod_aplicacao,
      );
      setValueAdmin(
        'aplicacao',
        cod_aplicacao !== undefined ? findAplicacoes : '',
      );
      setValueAdmin('cod_local', cod_cliente_local);
      // Busca autorizados
      {
        const { data } = await api.get(`/autorizados/${cod_cliente}`);
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((ent: Autorizado) => {
              const uuid = nanoid();
              return {
                uuid,
                id: uuid,
                cod_cliente,
                des_tipo_parentesco: `${ent.des_tipo_parentesco}`,
                nome_autorizado: `${ent.nome_autorizado}`,
                cod_autorizado: `${ent.cod_autorizado}`,
                num_documento: `${ent.num_documento}`,
                tipo_parentesco: `${ent.tipo_parentesco}`,
                cod_pessoa_autorizado: `${ent.cod_pessoa_autorizado}`,
              };
            });
            await addUpdate(
              'autorizados',
              options,
              masterDetail,
              setMasterDetail,
            );
            setAutorizados(options);
          }
        }
      }
      // Busca Limites de Crédito
      {
        const { data } = await api.get(`/limites/${cod_cliente}`);
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((lim: Credito) => {
              const uuid = nanoid();
              return {
                uuid,
                id: uuid,
                cod_cliente,
                des_finalizadora: `${lim.des_finalizadora}`,
                statusPdv: `${lim.des_status_pdv}`,
                val_limite: `${lim.val_limite}`,
                val_limite_view: `R$ ${formatCurrencyAsText(lim.val_limite)}`,
                saldo: lim.val_saldo,
                cod_finalizadora: lim.cod_finalizadora,
                cod_status_pdv: lim.cod_status_pdv,
              };
            });
            await addUpdate('limite', options, masterDetail, setMasterDetail);
            setLimitesDeCredito(options);
          }
        }
      }
      // Busca condicoes
      {
        const { data } = await api.get(`/cliente-condicao`, {
          params: {
            cod_pessoa,
            tipo_condicao: 1,
          },
        });
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((cond: Condicao) => {
              const uuid = nanoid();
              return {
                uuid,
                id: uuid,
                cod_seq_pessoa_condicao: cond.cod_seq_pessoa_condicao,
                num_condicao: `${cond.num_condicao}`,
                des_condicao: cond.des_condicao,
                des_finalizadora: cond.des_finalizadora,
                cod_finalizadora: cond.cod_finalizadora,
                cod_condicao: cond.cod_condicao,
              };
            });
            await addUpdate('condicao', options, masterDetail, setMasterDetail);
            setCondicoes(options);
          }
        }
      }
      // Busca Modulos
      {
        const { data } = await api.get(`/modulo-cliente/${cod_cliente}`);
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((cond: AnyObject) => {
              const uuid = nanoid();
              return {
                uuid,
                id: uuid,
                cod_seq_modulo: cond.cod_modulo,
                des_aplicacao: findAplicacoes.label,
                cod_aplicacao: findAplicacoes.value,
                cod_modulo: cond.cod_modulo,
                des_modulo: cond.des_modulo,
                flg_ativo: cond.flg_ativo,
                flg_demonstracao: cond.flg_demonstracao,
                dta_inicio: cond.dta_demonstracao_inicio,
                dta_final: cond.dta_demonstracao_fim,
              };
            });
            await addUpdate('modulo', options, masterDetail, setMasterDetail);
            setModulos(options);
          }
        }
      }
      {
        const { data } = await api.get(`/cliente-classificacao`, {
          params: {
            cod_pessoa,
          },
        });
        if (data.success) {
          if (data.message !== 'Nenhum registro encontrado.') {
            const options = data.data.map((classifi: AddClassificacao) => {
              return {
                label: classifi.des_classificacao,
                value: classifi.cod_classificacao,
              };
            });
            setValue('classificacao', options);
            setClassificacao(options);
          }
        }
      }

      setShowSearch(false);
      trigger('sexo');
    },
    [
      convenios,
      setLimitesDeCredito,
      indiceValorCondicao,
      indiceValorFinalizadora,
      setValue,
      setValueCondicao,
      masterDetail,
    ],
  );
  const resetFormData = useCallback(() => {
    setCidade(undefined);
    setClearCidade(true);
    setMasterDetail(masterDetailDefault);
    setTipoPessoa(0);
    setTab('geral');
    setValue('inativo', false);
    setFlagInativo(false);
    setValue('orgaoPublico', false);
    setTipoOrgaoPublico(undefined);
    setValue('tipoOrgaoPublico', undefined);
    setOrgaoPublico(false);
    setEmpresaConvenio(false);
    setValue('empresaConvenio', false);
    setMicroEmpresa(false);
    setValue('microEmpresa', false);
    setProdutorRural(false);
    setValue('produtorRural', false);
    reset(geral);
    resetLimite(limitDefaults);
    resetAutorizado(autorizadoDefaults);
    resetAdmin(moduloDefaults);
    resetCondicao(condicaoDefaults);
    handleClearAdministrativo();
    setAutorizados([]);
    setLimitesDeCredito([]);
    setCondicoes([]);
    setModulos([]);
    setModulosAEditar(undefined);
    setLimiteAEditar(undefined);
    setAutorizadoAEditar(undefined);
    setCondicaoAEditar('');
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setClassificacao(null);
    setPessoaExists(false);
    setTimeout(() => {
      setClearCidade(false);
    }, 1000);
  }, [
    reset,
    resetAutorizado,
    resetCondicao,
    resetLimite,
    setValue,
    setValueCondicao,
    masterDetailDefault,
  ]);

  /**
   * Métodos especificos da tela
   */

  const verifyFieldsEmpty = () => {
    const nameFields = Object.keys(getValues());
    const campos = getValues();

    campos.tipoPessoa = undefined;

    const hasNonEmptyFields = nameFields.some((nameField) => {
      return (
        campos[nameField] !== undefined &&
        campos[nameField] !== false &&
        campos[nameField] !== null &&
        campos[nameField] !== ''
      );
    });

    return hasNonEmptyFields;
  };

  const changeTipoPessoa = useCallback(
    async (val: number) => {
      /**
       * Muda o tipo de pessoa selecionada
       */

      if (verifyFieldsEmpty()) {
        const continua = await MySwal.fire({
          title: 'O tipo de pessoa foi alterado',
          text: 'Deseja limpar os campos já informados?',
          showCancelButton: true,
          confirmButtonColor: '#8850BF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
          return false;
        });
        if (continua) {
          setTab('geral');
          setPessoaExists(false);
          setTipoPessoa(val);
          resetFormData();
          setValue('tipoPessoa', val);
        } else if (val === 0) {
          setValue('num_cpf', getValues('num_cnpj'));
        } else {
          setValue('num_cnpj', getValues('num_cpf'));
        }
        setTipoPessoa(val);
      } else {
        setTipoPessoa(val);
      }
    },
    [getValues, isDirty, resetFormData, setValue],
  );
  const searchEmpresa = useCallback(async () => {
    setLoadingIcon('buscaPessoa');

    /**
     * Efetua busca dos dados da empresa
     */
    let cnpj = getValues('num_cnpj');

    if (loadingIcon !== 'buscaPessoa') {
      const isValidCnpj = documentoValido(cnpj);
      if (!isValidCnpj) {
        setLoadingIcon('');
        return;
      }
      cnpj = cnpj.replace(/[^\d]/g, '');
      const empresaData = await api.get(`/pessoas/num_cpf_cnpj/${cnpj}`);
      if (empresaData.data.success && empresaData.data.data.length > 0) {
        if (empresaData.data.data[0].flg_cliente) {
          setLoadingIcon('');
          const continua = await MySwal.fire({
            title: 'Cliente já cadastrado',
            text: `Já existe uma cliente cadastrado com documento ${cnpj}. Deseja editar o registro?`,
            showCancelButton: true,
            confirmButtonColor: '#8850BF',
            cancelButtonColor: '#DE350B',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              return true;
            }
            return false;
          });
          if (continua) {
            setTab('geral');
            const { data: clienteData } = await api.get('/cliente-pessoa', {
              params: { num_cpf_cnpj: cnpj },
            });
            if (
              clienteData.success &&
              clienteData.data &&
              clienteData.data.length > 0
            ) {
              const row: any = { row: clienteData.data[0] };
              onRowClick(row);
              setLoadingIcon('');
            }
            return;
          }
          setLoadingIcon('');
        } else {
          setValue('cod_pessoa', empresaData.data.data[0]?.cod_pessoa);
          setValue('name', empresaData.data.data[0]?.nome_pessoa);
          setValue('apelido', empresaData.data.data[0]?.apelido_fantasia);
          setValue('telefone', empresaData.data.data[0]?.num_telefone);
          setValue('celular', empresaData.data.data[0]?.num_celular);
          setValue('contato', empresaData.data.data[0]?.des_contato);
          setValue('email', empresaData.data.data[0]?.email);
          setValue('site', empresaData.data.data[0]?.site);
          setValue('cep', empresaData.data.data[0]?.num_cep);
          setValue(
            'num_ie',
            empresaData.data.data[0]?.num_ie === null
              ? ''
              : empresaData.data.data[0]?.num_ie,
          );
          setValue('logradouro', empresaData.data.data[0]?.des_logradouro);
          setValue('numero', empresaData.data.data[0]?.num_endereco);
          setValue('bairro', empresaData.data.data[0]?.des_bairro);
          setValue('complemento', empresaData.data.data[0]?.des_complemento);
          setCidade({
            cidade: empresaData.data.data[0]?.des_cidade,
            uf: empresaData.data.data[0]?.des_sigla,
          });
          setValue(
            'nascimentoAbertura',
            empresaData.data.data[0]?.dta_nascimento_abertura
              ? moment(empresaData.data.data[0]?.dta_nascimento_abertura)
                  .local()
                  .format('YYYY-MM-DD')
              : null,
          );
          setTimeout(() => {
            setLoadingIcon('');
          }, 300);
          toast.success(
            'Documento já cadastrado. Dados importados com sucesso.',
          );
        }
      } else {
        try {
          const data: ICNPJ = await axios.get(
            `https://brasilapi.com.br/api/cnpj/v1/${cnpj}`,
          );
          if (data.data) {
            setValue('name', data.data.razao_social);
            setValue('apelido', data.data.nome_fantasia);
            setValue('telefone', data.data.ddd_telefone_1.trim());
            setValue('celular', data.data.ddd_telefone_1.trim());
            setValue('cep', data.data.cep);
            setValue('logradouro', data.data.logradouro);
            setValue('numero', data.data.numero);
            setValue('bairro', data.data.bairro);
            setValue('complemento', data.data.complemento);
            setCidade({
              cidade: data.data.municipio,
              uf: data.data.uf,
            });
            setValue('nascimentoAbertura', data.data.data_inicio_atividade);
            nascimentoRef.current?.changeStartDate(
              data.data.data_inicio_atividade,
            );
            setLoadingIcon('');
            return;
          }
        } catch (error: any) {
          toast.warn(
            'Não foram encontrados dados públicos para o documento informado.',
          );
          setLoadingIcon('');
        }
      }
    }
  }, [getValues, setValue, tipoPessoa, onRowClick, resetFormData, loadingIcon]);

  const searchPessoa = useCallback(async () => {
    /**
     * Efetua busca dos dados da pessoa e
     * caso seja cnpj efetua busca com a função searchEmpresa
     */
    if (loadingIcon !== 'buscaPessoa') {
      setLoadingIcon('buscaPessoa');

      let cpf = getValues('num_cpf');
      const isValid = documentoValido(cpf);
      if (!isValid) {
        setLoadingIcon('');
        return;
      }
      cpf = cpf.replace(/[^\d]/g, '');
      const { data } = await api.get(`/pessoas/num_cpf_cnpj/${cpf}`);

      if (data.success && data.data && data.data.length > 0) {
        // Caso a pessoa já esteja cadastrada como cliente
        if (data.data[0].flg_cliente) {
          setLoadingIcon('');
          const continua = await MySwal.fire({
            title: 'Cliente já cadastrado',
            text: `Já existe um cliente cadastrado com documento ${cpf}. Deseja editar o registro?`,
            showCancelButton: true,
            confirmButtonColor: '#8850BF',
            cancelButtonColor: '#DE350B',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
          }).then((result) => {
            if (result.isConfirmed) {
              return true;
            }
            return false;
          });
          if (continua) {
            setTab('geral');
            const { data: clienteData } = await api.get('/cliente-pessoa', {
              params: { num_cpf_cnpj: cpf },
            });
            if (data.success && data.data && data.data.length > 0) {
              const row: any = { row: clienteData.data[0] };
              onRowClick(row);
              setLoadingIcon('');
            }

            return;
          }
          setLoadingIcon('');
        } else {
          setPessoaExists(true);
          if (Number(data.data[0].sexo) === 0) {
            setValue('sexo', {
              label: 'Masculino',
              value: 0,
            });
          } else {
            setValue('sexo', {
              label: 'Feminino',
              value: 1,
            });
          }
          setValue('cod_pessoa', data.data[0].cod_pessoa);
          setValue('cod_contato', data.data[0].cod_contato);
          setValue('cod_endereco', data.data[0].cod_endereco);
          setValue('num_rg_ie', data.data[0].num_rg_ie);
          setValue(
            'num_ie',
            data.data[0].num_ie === null ? '' : data.data[0].num_ie,
          );
          setValue('name', data.data[0].nome_pessoa);
          setValue('apelido', data.data[0].apelido_fantasia);
          setValue('apelido', data.data[0].apelido_fantasia);
          setValue('telefone', data.data[0].num_telefone);
          setValue('celular', data.data[0].num_celular);
          setValue('contato', data.data[0].des_contato);
          setValue('email', data.data[0].email);
          setValue('site', data.data[0].site);
          setValue('cep', data.data[0].num_cep);
          setValue('logradouro', data.data[0].des_logradouro);
          setValue('numero', data.data[0].num_endereco);
          setValue('bairro', data.data[0].des_bairro);
          setValue('complemento', data.data[0].des_complemento);
          setCidade({
            cidade: data.data[0].des_cidade,
            uf: data.data[0].des_sigla,
          });
          setValue(
            'nascimentoAbertura',
            data.data[0].dta_nascimento_abertura
              ? moment(data.data[0].dta_nascimento_abertura)
                  .local()
                  .format('YYYY-MM-DD')
              : null,
          );
          setLoadingIcon('');
          toast.success(
            'Documento já cadastrado. Dados importados com sucesso.',
          );
          return;
        }
      } else {
        toast.warn(
          'Não foram encontrados dados internos para o documento informado.',
        );
      }

      if (tipoPessoa === 1) {
        searchEmpresa();
        return;
      }
      setLoadingIcon('');
    }
  }, [
    getValues,
    onRowClick,
    resetFormData,
    searchEmpresa,
    setValue,
    tipoPessoa,
    loadingIcon,
  ]);

  const searchPessoaAutorizado = useCallback(async () => {
    /**
     * Efetua busca dos dados da pessoa autorizada
     */
    setLoadingIcon('cpfAutorizado');
    let cpf = getValuesAutorizado('cpfAutorizado');
    if (!cpf) {
      toast.warning('Informe um documento válido');
      setLoadingIcon('');
      return;
    }
    cpf = cpf.replace(/[^\d]/g, '');
    if (cpf === '') {
      toast.warning('Informe um documento válido');
      setLoadingIcon('');
      return;
    }
    const { data } = await api.get(`/pessoas/num_cpf_cnpj/${cpf}`);

    if (data.success && data.data && data.data.length > 0) {
      setCodigoPessoaAutorizada(data.data[0].cod_pessoa);
      setValueAutorizado('nomeAutorizado', data.data[0].nome_pessoa);
      setLoadingIcon('');
      return;
    }
    toast.warning(`Pessoa com documento ${cpf} não encontrada.`);
    setLoadingIcon('');
  }, [getValuesAutorizado, setValueAutorizado]);

  const searchCep = useCallback(async () => {
    if (loadingIcon !== 'cep') {
      setLoadingIcon('cep');
      let cep = getValues('cep');
      if (!cep) {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      cep = cep.replace(/[^\d]/g, '');
      if (cep === '') {
        toast.warning('CEP inválido');
        setLoadingIcon('');
        return;
      }
      try {
        const data: ICEP = await axios.get(
          `https://brasilapi.com.br/api/cep/v2/${cep}`,
        );
        setTimeout(() => {
          setClearCidade(true);

          clearErrors('logradouro');
          clearErrors('bairro');
          clearErrors('cidades');
          setValue(
            'logradouro',
            data.data.street && data.data.street.toUpperCase(),
          );
          setValue(
            'bairro',
            data.data.neighborhood && data.data.neighborhood.toUpperCase(),
          );
          setValue('cidades', {
            cidade: data.data.city && data.data.city.toUpperCase(),
            uf: data.data.state,
          });
          setCidade({
            cidade: data.data.city && data.data.city.toUpperCase(),
            uf: data.data.state,
          });
          setValue('latitude', data.data.location.coordinates.latitude);
          setValue('longitude', data.data.location.coordinates.longitude);
          setLoadingIcon('');
        }, 100);
      } catch (error: any) {
        setLoadingIcon('');
        toast.warn('CEP não encontrado');
      }
      setLoadingIcon('');
    }
  }, [getValues, setValue, loadingIcon]);

  const openWhatsApp = useCallback(() => {
    let celular = getValues('celular');
    const contato = getValues('contato');

    const regex = new RegExp(
      /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/g,
    );
    const validacao = !regex.test(celular);

    celular = celular.replace(/[^\d]/g, '');

    if (!celular || validacao) {
      toast.warning('Informe um número de celular válido');
      return;
    }

    celular = celular.replace(/[^\d]/g, '');
    if (!contato) {
      window.open(`https://api.whatsapp.com/send?phone=55${celular}`);
      return;
    }

    window.open(
      `https://api.whatsapp.com/send?phone=55${celular}&text=${encodeURI(
        `Olá, ${contato}`,
      )}`,
    );
  }, [getValues]);

  const openSite = useCallback((site) => {
    if (!site) {
      toast.warning('Informe um site válido');
      return;
    }

    if (site.includes('http')) {
      return window.open(site);
    }
    window.open(`https://${site}`);
  }, []);

  function handleOrgaoPublico() {
    setOrgaoPublico(!orgaoPublico);
    setValue('orgaoPublico', !orgaoPublico);
    if (!orgaoPublico === true) {
      setTipoOrgaoPublico(0);
      setValue('tipoOrgaoPublico', 0);
    } else {
      setTipoOrgaoPublico(undefined);
      setValue('tipoOrgaoPublico', undefined);
    }
  }

  function handleEmpresaConvenio() {
    setEmpresaConvenio(!empresaConvenio);
    setValue('empresaConvenio', !empresaConvenio);
    if (!empresaConvenio === false) {
      setValue('diaFechamento', null);
      clearErrors('diaFechamento');
    }
  }

  /**
   * Métodos de submit dos dados da tela para api
   */
  const prepareData = useCallback(
    (data: any) => {
      const cgc =
        tipoPessoa === 0 && !data.doc_autmatico ? data.num_cpf : data.num_cnpj;
      const num_rg_ie = data.num_rg_ie?.replace(/[^\d]/g, '');
      const num_cep = data.cep.replace(/[^\d]/g, '');
      const { num_ie } = data;
      const pessoa = {
        pessoaExists,
        doc_automatico: data.doc_automatico,
        cod_contato: data.cod_contato,
        cod_pessoa: data.cod_pessoa,
        cod_endereco: data.cod_endereco,
        nome_pessoa: data.name,
        apelido_fantasia: data.apelido,
        tipoPessoa,
        num_cpf_cnpj: cgc && cgc.replace(/[^\d]/g, ''),
        num_rg_ie,
        num_ie,
        sexo: data.sexo,
        dta_nascimento_abertura:
          data.nascimentoAbertura && data.nascimentoAbertura,
        flg_cliente: true,
        flg_orgao_publico: !!data.orgaoPublico,
        tipo_orgao_publico: data.tipoOrgaoPublico,
        flg_micro_empresa: !!data.microEmpresa,
        flg_produtor_rural: !!data.produtorRural,
        flg_empresa_convenio: !!data.empresaConvenio,
        classificacao: data.classificacao,
        contato: {
          num_telefone: data.telefone,
          num_celular: data.celular,
          des_contato: data.contato,
          site: data.site,
          email: data.email,
        },
        endereco: {
          num_cep,
          des_logradouro: data.logradouro,
          num_endereco: data.numero,
          des_complemento: data.complemento,
          des_bairro: data.bairro,
          des_cidade: data.cidades.label.split(' - ')[0],
          cod_cidade: data.cidades.value,
          des_uf: data.cidades.label.split(' - ')[1],
          des_referencia: data.referencia,
          latitude: data.latitude,
          longitude: data.longitude,
        },
      };
      const cliente = {
        cod_cliente: data.cod_cliente,
        cod_convenio: data.conveniado ? data.conveniado.value : null,
        des_empresa_trab: data.desEmpresaTrabalho,
        num_telefone_trab: data.telefoneTrabalho,
        des_cargo_trab: data.cargo,
        dta_admissao_trab: data.admissao && data.admissao,
        flg_inativo: !!data.inativo,
        flg_emp_convenio: !!data.empresaConvenio,
        num_dia_fecha: data.diaFechamento,
        cod_local: Number(getValuesAdmin('cod_local')),
        cod_aplicacao: getValuesAdmin('aplicacao')?.value,
      };
      return {
        pessoa,
        cliente,
        limitesDeCredito,
        autorizados,
        condicoes,
        modulos,
        masterDetail,
      };
    },
    [
      autorizados,
      limitesDeCredito,
      condicoes,
      pessoaExists,
      tipoPessoa,
      masterDetail,
    ],
  );

  const onSubmit = handleSubmit(async (data) => {
    data.doc_automatico = false;
    data.num_rg_ie = data.num_rg_ie || '';
    data.telefone = data.telefone || '';
    data.diaFechamento = data.diaFechamento || '';
    data.telefoneTrabalho = data.telefoneTrabalho || '';

    setTab('geral');
    if (
      (tipoPessoa === 0 &&
        (data.num_cpf.replace(/[^\d]/g, '') === '' || !data.num_cpf)) ||
      (tipoPessoa === 1 &&
        (data.num_cnpj === '' || !data.num_cnpj) &&
        !isUpdate)
    ) {
      setLoading(false);
      const continua = await MySwal.fire({
        title: 'Confirmação',
        text: `Deseja incluir ${
          tipoPessoa !== 1 ? 'CPF' : 'CNPJ'
        }  gerado internamente?`,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          return true;
        }
        return false;
      });
      if (continua) {
        data = {
          ...data,
          doc_automatico: true,
        };
      } else {
        tipoPessoa === 0
          ? setError('num_cpf', {
              type: 'error',
            })
          : setError('num_cnpj', {
              type: 'error',
            });
        return;
      }
    }

    if (
      (data.num_cnpj || data.num_cpf.replace(/[^\d]/g, '') !== '') &&
      !data.doc_automatico
    ) {
      const isValid =
        tipoPessoa === 0
          ? documentoValido(data.num_cpf)
          : documentoValido(data.num_cnpj);
      if (!isValid) {
        tipoPessoa === 0
          ? setError('num_cpf', {
              type: 'error',
            })
          : setError('num_cnpj', {
              type: 'error',
            });
        return;
      }
    }
    data.num_ie = data.num_ie ? data.num_ie.trim() : '';
    if ((data.num_ie === '' || !data.num_ie) && tipoPessoa === 1) {
      setLoading(false);
      const result = await MySwal.fire({
        title: 'Confirmação',
        text: 'Inscrição Estadual não foi informada. Deseja cadastrá-lo como "ISENTO"',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      });

      if (!result.isConfirmed) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
      data.num_ie = 'ISENTO';
    } else if (tipoPessoa === 1) {
      const ie = getValues('num_ie');
      const cidades = getValues('cidades');

      if (!cidades) {
        return true; // No validation if no state is selected
      }

      if (!validar(ie, cidades.ufValue)) {
        setError('num_ie', {
          type: 'error',
        });
        return;
      }
    }
    try {
      setLoading(true);
      const formatedData = prepareData(data);
      if (isUpdate) {
        const { data: dataReturn }: any = await api.put(
          '/cliente',
          formatedData,
        );
        if (dataReturn.success) {
          toast.success(dataReturn.message);
          setShowSearch(true);
          setUpdate(false);
          resetFormData();
          return;
        }
      }
      const { data: dataReturn }: any = await api.post(
        '/cliente',
        formatedData,
      );
      if (dataReturn.success) {
        toast.success(dataReturn.message);
        resetFormData();
        return;
      }
    } catch (error) {
      setLoader(false);
    }
  });

  const onDelete = useCallback(async () => {
    const codCliente = getValues('cod_cliente');
    if (!codCliente) {
      toast.warning('Cliente não encontrado');
      return;
    }
    const { data } = await api.delete(`/cliente/${codCliente}`);

    if (data.success) {
      toast.success('Cliente Inativado com sucesso');
      resetFormData();
      setUpdate(false);
      setShowSearch(true);
    }
  }, [getValues, resetFormData]);
  /**
   * Métodos dos limites
   */
  const handleClearlLimite = useCallback(() => {
    setLimiteAEditar(undefined);
    resetLimite(limitDefaults);
  }, [resetLimite]);

  const handleLimite = handleSubmitLimite(async (data) => {
    const uuid =
      limiteAEditar?.uuid === undefined ? nanoid() : limiteAEditar?.uuid;

    const exists = limitesDeCredito.filter(
      (limiteData) =>
        limiteData.cod_finalizadora === data.finalizadora.value &&
        limiteData.uuid !== limiteAEditar?.uuid,
    );

    if (exists.length > 0) {
      toast.warning(
        `Já existe um limite para a finalizadora ${data.finalizadora.label}.`,
      );
      return;
    }

    const limiteData: ICredito = {
      uuid,
      id: uuid,
      cod_cliente: limiteAEditar?.cod_cliente,
      des_finalizadora: data.finalizadora.label,
      val_limite_view: `R$ ${data.limiteDeCredito}`,
      val_limite: String(transformAsCurrency(data.limiteDeCredito)),
      statusPdv: data.statusPdv.label,
      cod_finalizadora: data.finalizadora.value,
      cod_status_pdv: data.statusPdv.value,
      debito: 0,
      saldo: transformAsCurrency(data.limiteDeCredito),
    };
    const limiteCredito: any[] = await insertOrUpdate(
      'limite',
      limiteData,
      masterDetail,
      setMasterDetail,
    );
    setLimitesDeCredito(limiteCredito);
    handleClearlLimite();
  });

  /**
   * Métodos dos autorizados
   */
  const handleClearAutorizado = useCallback(() => {
    setCodigoPessoaAutorizada(undefined);
    setAutorizadoAEditar(undefined);
    resetAutorizado(autorizadoDefaults);
  }, [resetAutorizado]);

  const handleAutorizado = handleSubmitAutorizado(async (data) => {
    const uuid =
      autorizadoAEditar?.uuid === undefined
        ? nanoid()
        : autorizadoAEditar?.uuid;
    data.cpfAutorizado = data.cpfAutorizado.replace(/[^\d]/g, '');

    const exists = autorizados.filter(
      (aut) => aut.num_documento === data.cpfAutorizado && aut.uuid !== uuid,
    );
    if (exists.length > 0) {
      toast.warning(
        `Já existe um autorizado vinculado ao documento ${data.cpfAutorizado}.`,
      );
      return;
    }
    const autorizadoData: AddAutorizado = {
      uuid,
      id: uuid,
      cod_cliente: autorizadoAEditar?.cod_cliente,
      des_tipo_parentesco: data.parentesco.label,
      tipo_parentesco: data.parentesco.value,
      nome_autorizado: data.nomeAutorizado,
      cod_autorizado: data.codigoAutorizado,
      num_documento: data.cpfAutorizado.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4',
      ),
      cod_pessoa_autorizado: codigoPessoaAutorizada,
    };

    const autorizadosDetail: any[] = await insertOrUpdate(
      'autorizados',
      autorizadoData,
      masterDetail,
      setMasterDetail,
    );
    setAutorizados(autorizadosDetail);
    handleClearAutorizado();
    resetAutorizado(autorizadoDefaults);
  });

  /**
   * Métodos das condições
   */
  const handleClearCondicao = useCallback(() => {
    setDiasCondicao(30);
    setCondicaoAEditar('');
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
  }, [setValueCondicao]);

  const handleClearAdministrativo = () => {
    setModulosAEditar('');
    setValueAdmin('cod_modulo', undefined);
    setValueAdmin('flg_ativo', false);
    setValueAdmin('flg_demostracao', false);
    setValueAdmin('dta_inicio', '');
    setValueAdmin('dta_fim', '');
    setFlgAtivo(false);
    setFlgDemonstracao(false);
  };

  const handleCondicao = handleSubmitCondicao(async (data) => {
    const uuid =
      condicaoAEditar?.uuid === undefined ? nanoid() : condicaoAEditar?.uuid;

    const exists = condicoes.filter(
      (cond) =>
        cond.cod_finalizadora === data.condicaoFinalizadora.value &&
        cond.cod_condicao === data.condicao.value &&
        Number(cond.num_condicao) === data.numCondicao &&
        condicaoAEditar.uuid !== cond.uuid,
    );
    if (exists.length > 0) {
      toast.warning(`Já existe uma condição com essas informações.`);
      return;
    }
    const condicaoData: ICondicao = {
      uuid,
      id: uuid,
      cod_seq_pessoa_condicao: condicaoAEditar?.cod_seq_pessoa_condicao,
      num_condicao: data.numCondicao,
      des_condicao: data.condicao.label,
      des_finalizadora: data.condicaoFinalizadora.label,
      cod_finalizadora: data.condicaoFinalizadora.value,
      cod_condicao: data.condicao.value,
    };

    const condicaoDetail: any[] = await insertOrUpdate(
      'condicao',
      condicaoData,
      masterDetail,
      setMasterDetail,
    );
    setCondicoes(condicaoDetail);
    setValueCondicao('condicao', { label: 'DIAS DA DATA - DD', value: 2 });
    setValueCondicao('numCondicao', 30);
    setValueCondicao('condicaoFinalizadora', '');
  });

  const handleAdministrativo = handleSubmitAdmin(async (data) => {
    const days = moment(data.dta_final).diff(data.dta_inicio, 'days');

    if (days < 0) {
      toast.warning('A data final não pode ser menor que a data inicial ');
      return;
    }
    const uuid =
      modulosAEditar?.uuid === undefined ? nanoid() : modulosAEditar?.uuid;
    const exists = modulos.filter(
      (cond) =>
        cond.cod_modulo === data.cod_modulo.value &&
        modulosAEditar?.uuid !== cond.uuid,
    );
    if (exists.length > 0) {
      toast.warning(`Já existe um módulo com essas informações.`);
      return;
    }
    const condicaoData: IModulo = {
      uuid,
      id: uuid,
      cod_seq_modulo: modulosAEditar?.cod_seq_modulo,
      cod_modulo: data.cod_modulo.value,
      des_modulo: data.cod_modulo.label,
      cod_local: data.cod_local,
      flg_ativo: data.flg_ativo,
      flg_demonstracao: data.flg_demonstracao,
      cod_aplicacao: data.aplicacao.value,
      des_aplicacao: data.aplicacao.label,
      dta_inicio: data.dta_inicio,
      dta_final: data.dta_final,
    };

    const condicaoDetail: any[] = await insertOrUpdate(
      'modulo',
      condicaoData,
      masterDetail,
      setMasterDetail,
    );
    setModulos(condicaoDetail);
    setValueAdmin('cod_modulo', null);
    setValueAdmin('flg_ativo', false);
    setFlgAtivo(false);
    setFlgDemonstracao(false);
    setModulosAEditar(false);
    setValueAdmin('flg_demonstracao', false);
    setValueAdmin('dta_inicio', '');
    setValueAdmin('dta_final', '');
  });

  const SaveCondicao = () => {
    setInitFinalizadora(true);
    handleCondicao();
    handleClearCondicao();
    setCondicaoAEditar('');
  };

  const contextValue = {
    loader,
    setLoader,
    isUpdate,
    setUpdate,
    showSearch,
    setShowSearch,
    tab,
    setTab,
    iniInicializado,
    setInitInicializado,
    codClienteData,
    setCodClienteData,
    tipoPessoa,
    setTipoPessoa,
    pessoaExists,
    setPessoaExists,
    loadingIcon,
    setLoadingIcon,
    flagInativo,
    setFlagInativo,
    nascimentoRef,
    convenios,
    conveniosAtivos,
    setConvenios,
    finalizadoras,
    setFinalizadoras,
    statusDoPdv,
    setStatusPdv,
    classificacao,
    setClassificacao,
    diasCondicao,
    setDiasCondicao,
    orgaoPublico,
    setOrgaoPublico,
    tipoOrgaoPublico,
    setTipoOrgaoPublico,
    empresaConvenio,
    setEmpresaConvenio,
    microEmpresa,
    setMicroEmpresa,
    produtorRural,
    setProdutorRural,
    admissaoRef,
    limiteAEditar,
    setLimiteAEditar,
    limitesDeCredito,
    setLimitesDeCredito,
    masterDetail,
    setMasterDetail,
    autorizadoAEditar,
    setAutorizadoAEditar,
    codigoPessoaAutorizada,
    setCodigoPessoaAutorizada,
    autorizados,
    setAutorizados,
    condicoes,
    setCondicoes,
    clickSubmit,
    setClickSubmit,
    initFinalizadora,
    setInitFinalizadora,
    condicoesOptions,
    setCondicoesOptions,
    condicaoAEditar,
    setCondicaoAEditar,
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { isDirty, errors },
    watch,
    watchTipoPessoa,
    limiteRegister,
    handleSubmitLimite,
    limiteControl,
    resetLimite,
    setValueLimite,
    formStateLimite,
    autorizadoRegister,
    handleSubmitAutorizado,
    autorizadoControl,
    resetAutorizado,
    setValueAutorizado,
    getValuesAutorizado,
    formStateAutorizado,
    condicaoRegister,
    handleSubmitCondicao,
    condicaoControl,
    resetCondicao,
    setValueCondicao,
    formStateCondicao,
    getArrayIndexed,
    indiceValorCondicao,
    indiceValorFinalizadora,
    onRowClick,
    resetFormData,
    changeTipoPessoa,
    searchEmpresa,
    searchPessoa,
    searchPessoaAutorizado,
    searchCep,
    cidade,
    openWhatsApp,
    openSite,
    handleOrgaoPublico,
    handleEmpresaConvenio,
    onSubmit,
    onDelete,
    handleClearlLimite,
    handleLimite,
    handleAutorizado,
    SaveCondicao,
    handleClearCondicao,
    handleClearAutorizado,
    classificacaoOptions,
    clearErrors,
    moduloAdmin,
    registerAdmin,
    handleSubmitAdmin,
    controlAdmin,
    resetAdmin,
    setValueAdmin,
    formStateAdmin,
    getValuesAdmin,
    setErrorAdmin,
    watchAdmin,
    clearErrorsAdmin,
    handleAdministrativo,
    modulos,
    setModulos,
    modulosAEditar,
    setModulosAEditar,
    flgDemonstracao,
    setFlgDemonstracao,
    flgAtivo,
    setFlgAtivo,
    modulosOptions,
    aplicacoesData,
    handleClearAdministrativo,
    clearCidade,
    getConvenios,
  };
  return (
    <ClienteContext.Provider value={contextValue}>
      {children}
    </ClienteContext.Provider>
  );
}

export function useClienteContext() {
  const context = useContext(ClienteContext);
  if (!context) {
    throw new Error(
      'useAlteracaoCusto deve ser usado dentro de um AlteracaoCustoProvider',
    );
  }
  return context;
}
